import { createContext, useReducer } from 'react'
import { getCookieConsentValue } from 'react-cookie-consent'

type State = {
  accepted: boolean
}

type Action = 'accepted' | 'declined' | 'reset'

const initialState = { accepted: false }

export const CookieContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

function cookieReducer(state: State, action: Action): State {
  switch (action) {
    case 'accepted': {
      return { accepted: true }
    }
    case 'declined': {
      return { accepted: false }
    }
    case 'reset': {
      return initialState
    }
    default:
      return state
  }
}

const CookieProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(cookieReducer, initialState, () => ({
    accepted: JSON.parse(getCookieConsentValue('hasAgreedCookies') || 'false'),
  }))

  return <CookieContext.Provider value={{ state, dispatch }}>{children}</CookieContext.Provider>
}

export default CookieProvider
