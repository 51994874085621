import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { ChakraProvider } from '@chakra-ui/react'
import { ModalProvider } from '@components/Modal/provider'
import CookieProvider from '@src/react-context/cookie-context'
import '../styles/globals.css'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

function App({ Component, pageProps, gtmId }) {
  useEffect(() => {
    TagManager.initialize({ gtmId })
  }, [])
  return (
    <ThemeProvider>
      <ChakraProvider>
        <CookieProvider>
          <CSSReset />
          <ModalProvider openYourAccountAlert={pageProps?.openYourAccountAlert}>
            <Component {...pageProps} />
          </ModalProvider>

          <script src="https://unpkg.com/blip-chat-widget" type="text/javascript"></script>
        </CookieProvider>
      </ChakraProvider>
    </ThemeProvider>
  )
}

App.getInitialProps = async () => {
  return { gtmId: process.env.GTM_ID }
}
export default App
